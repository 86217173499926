.textWhite {
    color: white;
}

.textMain {
    color: #00b3e2;
}

.textBold {
    font-weight: 700 !important;
}

.list {
    padding: 0 0 0 35px !important;
    list-style-type: disc !important;
}
