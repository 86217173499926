.carouselNext,
.carouselPrev {
    color: #ffffff;
    font-weight: 900 !important;
    font-size: 1.2em !important;
}

@media (min-width: 1200px) {
    .carouselNext,
    .carouselPrev {
        font-size: 1.2em !important;
    }
}

.avatarSelect {
    width: 18px !important;
    height: 18px !important;
    background-color: #00b3e2 !important;
}

.checkIcon {
    width: 14px !important;
    height: 14px !important;
    color: #fff !important;
}
