.normal {
    visibility: visible;
    opacity: 1;
}

.shake {
    animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.fadeAnswer {
    animation: fadeOut 0.2s ease-in 0.2s forwards;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    to {
        opacity: 0.2;
        /* visibility: hidden; */
    }
}
