.tdHeader {
    color: #ffffff !important;
    font-size: 0.7rem !important;
    text-transform: uppercase;
}

.tdBorder {
    border-bottom: 1px solid hsl(240, 1%, 28%) !important;
    padding: 4px 0px !important;
}

.tdPlayerPosition {
    border-left: 0px;
    border-right: 0px;
    border-top: 2px;
    border-bottom: 2px;
    border-style: solid;
    border-color: #00b3e2 !important;
}

.tdPlayerPositionStart {
    border-left: 2px;
    border-right: 0px;
    border-top: 2px;
    border-bottom: 2px;
    border-style: solid;
    border-color: #00b3e2 !important;
}

.tdPlayerPositionEnd {
    border-left: 0px;
    border-right: 2px;
    border-top: 2px;
    border-bottom: 2px;
    border-style: solid;
    border-color: #00b3e2 !important;
}

.tdBorderIndexBG {
    background-color: rgba(217, 217, 217, 0.05);
    padding: 4px 4px !important;
}

.avatarCSS {
    width: 24px !important;
    height: 24px !important;
}

.starCSS {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #303032;
    margin-top: -10px;
}

@media (min-width: 900px) {
    .avatarCSS {
        width: 24px !important;
        height: 24px !important;
    }
}
