.iframe {
    width: 100%;
    height: 100%;
    /* border: 2px solid #09c; */
}

.carouselNext,
.carouselPrev {
    color: #ffffff;
    font-weight: 900 !important;
    font-size: 1.2em !important;
}

@media (min-width: 1200px) {
    .carouselNext,
    .carouselPrev {
        font-size: 1.2em !important;
    }
}
