.btn-powerUp:disabled {
    background-color: transparent !important;
}

.inactivePowerUp {
    opacity: 0.2;
    background-color: transparent !important;
}

.img-powerUp {
    width: 75px;
}
