.containerTitle {
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(255, 255, 255, 0)
    );
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 4px;
    margin: 0px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.containerHost {
    position: absolute;
    top: 2px;
    left: 2px;
    padding: 0px;
    background-color: #2b2b2b !important;
    cursor: default;
    color: white !important;
    border: 0px !important;
}
