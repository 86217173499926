.playerScore {
    position: absolute;
    bottom: -10px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    color: rgba(0, 0, 0, 0.87);

    font-weight: 500 !important;

    line-height: 1;
    padding: 2px 8px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}

.playerEdit {
    width: 26px !important;
    height: 26px !important;
    background-color: #00b3e2 !important;
}

.editIcon {
    width: 16px !important;
    height: 16px !important;
    color: #fff !important;
}

.link {
    text-decoration: none;
}

.linkSmall {
    color: #00b3e2 !important;
    font-size: 12px;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.statsNumber {
    min-width: 100px;
}

@media (min-width: 600px) {
    .statsNumber {
        min-width: 150px;
    }
}
