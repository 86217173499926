.tdHeader {
    color: #ffffff !important;
    font-size: 0.7rem !important;
    text-transform: uppercase;
    padding: 0px !important;
}

.titleHeader {
    font-size: 0.9rem !important;
}

.legendHeader {
    color: #ffffff !important;
    font-size: 0.9rem !important;
    text-transform: uppercase;
    color: #00b3e2 !important;
}

.tdBorder {
    border-bottom: 1px solid hsl(240, 1%, 28%) !important;
    padding: 4px 0px !important;
}

.tdBorderIndexBG {
    background-color: rgba(217, 217, 217, 0.05);
    padding: 4px 4px !important;
}
