.circleBG {
    display: none;
    position: absolute;
    width: 250px;
}

.squareBG {
    display: none;
    position: absolute;
    width: 250px;
}

@media (min-width: 1200px) {
    .circleBG {
        display: block;
        left: -175px;
        top: 50px;
    }

    .squareBG {
        display: block;
        right: -175px;
        top: 125px;
        transform: rotate(30deg);
    }
}
