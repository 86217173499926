.backgroundColor {
    background-color: #181818 !important;
}

.bgIconsDefault {
    display: none;
    position: absolute;
    width: 250px;
}

.exclamationBG {
    display: none;
    position: absolute;
    width: 150px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@media (min-width: 1200px) {
    .circleBG {
        display: block;
        left: -45%;
        top: -5%;
    }

    .questionBG {
        display: block;
        right: -45%;
        top: -5%;
    }

    .squareBG {
        display: block;
        right: -45%;
        bottom: -2%;
        transform: rotate(30deg);
    }

    .exclamationBG {
        display: block;
        left: -40%;
        bottom: -5%;
    }
}

@media (min-width: 1536px) {
    .circleBG {
        display: block;
        left: -60%;
        top: -5%;
    }

    .questionBG {
        display: block;
        right: -60%;
        top: -5%;
    }

    .squareBG {
        display: block;
        right: -60%;
        bottom: -5%;
        transform: rotate(30deg);
    }

    .exclamationBG {
        display: block;
        left: -50%;
        bottom: -10%;
    }
}
