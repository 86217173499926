body {
    margin: 0;
    font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "DM Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #181818 !important;
    color: #fafafa !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    /* font-family: "DM Sans", sans-serif !important; */
}

.mojo-coin-title {
    width: 20px;
    height: 20px;
}

.mojo-coin {
    width: 16px;
    height: 16px;
}

.cash-battle-icon {
    height: 20px;
    margin-left: 8px;
}

.powerup-tips {
    height: 13px;
}

.thinScroll {
    scrollbar-width: thin;
    scrollbar-track-color: #888;
    scrollbar-color: rgba(0, 179, 226, 1) #888;
}

/* width */
.thinScroll::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
}

/* Track */
.thinScroll::-webkit-scrollbar-track {
    background: #888;
}

/* Handle */
.thinScroll::-webkit-scrollbar-thumb {
    background: rgba(0, 179, 226, 1);
}

/* Handle on hover */
.thinScroll::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 179, 226, 0.5);
}
