.playersContainer {
  scrollbar-width: thin;
  scrollbar-track-color: #888;
  scrollbar-color:rgba(0,179,226,1) #888;
}

/* width */
.playersContainer::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.playersContainer::-webkit-scrollbar-track {
  background: #888; 
}
 
/* Handle */
.playersContainer::-webkit-scrollbar-thumb {
  background: rgba(0,179,226, 1); 
}

/* Handle on hover */
.playersContainer::-webkit-scrollbar-thumb:hover {
  background: rgba(0,179,226, 0.5); 
}