.btnSubmitAnswer:hover {
    background-color: #00b3e2;
}

.btnSubmitAnswer:disabled {
    background-color: grey !important;
}

.answerBox {
    color: white;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid white;
    padding: 8px;
    letter-spacing: 0.08rem;
    border-radius: 4px;
    outline: none;
    text-align: center;
    flex-grow: unset;
    width: 100%;
}

.answerBox:focus,
.answerBox:active {
    border-color: #00b3e2;
}

.answerBox:disabled {
    border-color: grey;
}

@media (min-width: 900px) {
    .answerBox {
        font-size: 20px;
        font-weight: 700;
        width: auto;
        flex-grow: 1;
    }
}
