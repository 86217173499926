.listItemDefault {
    width: auto;
    padding: 10px !important;
}

.active {
    opacity: 1;
}

.inactive {
    opacity: 0.5;
}

.absoluteCenter {
    position: absolute;
    bottom: 0% !important;
    left: 50% !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    padding: 1px 6px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    min-width: 30px;
}

.playerCorrect {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    color: #3ffb3f;
}

.crown {
    height: 50px;
    width: 50px;
    display: block;
    /* position: absolute; */
    top: -20px;
    /* left: 50%; */
    /* -ms-transform: translateX(-50%) !important; */
    /* transform: translateX(-50%) !important; */
    font-size: 50px;
    color: #ff9900;
}

.hasCrown {
    height: 35px !important;
    width: 35px !important;
    display: block !important;
    position: absolute !important;
    top: -20px !important;
    left: 50% !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
}

.noCrown {
    display: none;
}

.isMe {
    border: 1px solid #00b3e2 !important;
}

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

/* Position tooltip above the element */
[data-tooltip]:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 0px;
    margin-left: -80px;
    padding: 10px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #00b3e2;
    background-color: #00b3e2(0, 0%, 20%, 0.9);
    color: #000;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #00b3e2;
    border-top: 5px solid #00b3e2(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
}

.loyaltyRibbonBox {
    position: absolute;
    bottom: -18px;
    left: -5px;
}

.loyaltyRibbon {
    font-size: 30px;
    color: #00b3e2;
}

.loyaltyRibbonText {
    position: absolute;
    top: 5px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    color: black;
    background-color: #ffdf00;
    border-radius: 50%;

    min-width: 1em; /* em unit */
    padding: 0.3em; /* em unit */
    border-radius: 50%;
    text-align: center;
    line-height: normal !important;
}

@media (min-width: 1300px) {
    .loyaltyRibbonBox {
        position: absolute;
        bottom: -18px;
        left: -10px;
    }

    .loyaltyRibbon {
        font-size: 36px;
    }

    .loyaltyRibbonText {
        top: 6px;
        min-width: 1em; /* em unit */
        padding: 0.3em; /* em unit */
        border-radius: 50%;
    }
}
